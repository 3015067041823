

// font
@font-face
  font-family: 'Sukhumvit Set'
  src: url('assets/fonts/sukhumvit-set-text.woff2') format('woff2')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'SukhumvitSet'
  src: url('assets/fonts/sukhumvit-set-text.woff2') format('woff2')
  font-weight: normal
  font-style: normal


@font-face
  font-family: 'sukhumvit'
  src: url('assets/fonts/sukhumvit.eot')
  src: url('assets/fonts/sukhumvit.eot?#iefix') format('embedded-opentype')
  src: url('assets/fonts/sukhumvit.woff2') format('woff2')
  src: url('assets/fonts/sukhumvit.woff') format('woff')
  src: url('assets/fonts/sukhumvit.ttf') format('truetype')
  font-weight: normal
  font-style: normal

// SFPro
// Text
@font-face {
	font-family: "SF Pro Text";
	src: url("assets/fonts/sf-pro-text_regular.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	font-display: swap;
}

@font-face {
	font-family: "SF Pro Text";
	src: url("assets/fonts/SFProThai-Regular.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	font-display: swap;
	unicode-range: U+0E00-0E7F; // For thai word
}

@font-face {
	font-family: "SF Pro Text";
	src: url("assets/fonts/sf-pro-text_medium.woff2") format("woff2");
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	font-display: swap;
}

@font-face {
	font-family: "SF Pro Text";
	src: url("assets/fonts/SFProTH.woff2") format("woff2");
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	font-display: swap;
	unicode-range: U+0E00-0E7F; // For thai word
}

@font-face {
	font-family: "SF Pro Text";
	src: url("assets/fonts/sf-pro-text_semibold.woff2") format("woff2");
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	font-display: swap;
}

@font-face {
	font-family: "SF Pro Text";
	src: url("assets/fonts/SFProThai-Semibold.woff2") format("woff2");
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	font-display: swap;
	unicode-range: U+0E00-0E7F; // For thai word
}

@font-face {
	font-family: "SF Pro Text";
	src: url("assets/fonts/sf-pro-text_bold.woff2") format("woff2");
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
	font-display: swap;
}

@font-face {
	font-family: "SF Pro Text";
	src: url("assets/fonts/SFProThai-Semibold.woff2") format("woff2");
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
	font-display: swap;
	unicode-range: U+0E00-0E7F; // For thai word
}

// Display
@font-face {
	font-family: "SF Pro Display";
	src: url("assets/fonts/sf-pro-display_semibold.woff2") format("woff2");
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	font-display: swap;
}

@font-face {
	font-family: "SF Pro Display";
	src: url("assets/fonts/SFProThai-Semibold.woff2") format("woff2");
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	font-display: swap;
	unicode-range: U+0E00-0E7F; // For thai word
}

// [END] SFPro

body
  color: #434242
  font-family: 'sukhumvit', 'Sukhumvit Set', 'Prompt', 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif !important

.container{
    max-width: 100dvw
    padding 0 12px

    @media screen and (min-width: 768px){
        max-width: 1220px
    }

    @media (min-width: 1024){
        max-width: 1220px !important
    }
}

.header-margin
  height: 100px

.footer
  height: 60px
  background-color: $color-gray-400
  z-index 7
