$body-font-family: 'SF Pro Text', 'SF Pro Display', 'Sukhumvit Set',
  'Prompt', 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial,
  sans-serif !important;
$heading-font-family: $body-font-family;

.padding-xs {
  padding: 0.25em;
}
.padding-sm {
  padding: 0.5em;
}
.padding-md {
  padding: 1em;
}
.padding-lg {
  padding: 1.5em;
}
.padding-xl {
  padding: 3em;
}

.padding-x-xs {
  padding: 0.25em 0;
}
.padding-x-sm {
  padding: 0.5em 0;
}
.padding-x-md {
  padding: 1em 0;
}
.padding-x-lg {
  padding: 1.5em 0;
}
.padding-x-xl {
  padding: 3em 0;
}

.padding-y-xs {
  padding: 0 0.25em;
}
.padding-y-sm {
  padding: 0 0.5em;
}
.padding-y-md {
  padding: 0 1em;
}
.padding-y-lg {
  padding: 0 1.5em;
}
.padding-y-xl {
  padding: 0 3em;
}

.padding-top-xs {
  padding-top: 0.25em;
}
.padding-top-sm {
  padding-top: 0.5em;
}
.padding-top-md {
  padding-top: 1em;
}
.padding-top-lg {
  padding-top: 1.5em;
}
.padding-top-xl {
  padding-top: 3em;
}

.padding-right-xs {
  padding-right: 0.25em;
}
.padding-right-sm {
  padding-right: 0.5em;
}
.padding-right-md {
  padding-right: 1em;
}
.padding-right-lg {
  padding-right: 1.5em;
}
.padding-right-xl {
  padding-right: 3em;
}

.padding-bottom-xs {
  padding-bottom: 0.25em;
}
.padding-bottom-sm {
  padding-bottom: 0.5em;
}
.padding-bottom-md {
  padding-bottom: 1em;
}
.padding-bottom-lg {
  padding-bottom: 1.5em;
}
.padding-bottom-xl {
  padding-bottom: 3em;
}

.padding-left-xs {
  padding-left: 0.25em;
}
.padding-left-sm {
  padding-left: 0.5em;
}
.padding-left-md {
  padding-left: 1em;
}
.padding-left-lg {
  padding-left: 1.5em;
}
.padding-left-xl {
  padding-left: 3em;
}

.margin-xs {
  margin: 0.25em;
}
.margin-sm {
  margin: 0.5em;
}
.margin-md {
  margin: 1em;
}
.margin-lg {
  margin: 1.5em;
}
.margin-xl {
  margin: 3em;
}

.margin-x-xs {
  margin: 0.25em 0;
}
.margin-x-sm {
  margin: 0.5em 0;
}
.margin-x-md {
  margin: 1em 0;
}
.margin-x-lg {
  margin: 1.5em 0;
}
.margin-x-xl {
  margin: 3em 0;
}

.margin-y-xs {
  margin: 0 0.25em;
}
.margin-y-sm {
  margin: 0 0.5em;
}
.margin-y-md {
  margin: 0 1em;
}
.margin-y-lg {
  margin: 0 1.5em;
}
.margin-y-xl {
  margin: 0 3em;
}

.margin-top-xs {
  margin-top: 0.25em;
}
.margin-top-sm {
  margin-top: 0.5em;
}
.margin-top-md {
  margin-top: 1em;
}
.margin-top-lg {
  margin-top: 1.5em;
}
.margin-top-xl {
  margin-top: 3em;
}

.margin-right-xs {
  margin-right: 0.25em;
}
.margin-right-sm {
  margin-right: 0.5em;
}
.margin-right-md {
  margin-right: 1em;
}
.margin-right-lg {
  margin-right: 1.5em;
}
.margin-right-xl {
  margin-right: 3em;
}

.margin-bottom-xs {
  margin-bottom: 0.25em;
}
.margin-bottom-sm {
  margin-bottom: 0.5em;
}
.margin-bottom-md {
  margin-bottom: 1em;
}
.margin-bottom-lg {
  margin-bottom: 1.5em;
}
.margin-bottom-xl {
  margin-bottom: 3em;
}

.margin-left-xs {
  margin-left: 0.25em;
}
.margin-left-sm {
  margin-left: 0.5em;
}
.margin-left-md {
  margin-left: 1em;
}
.margin-left-lg {
  margin-left: 1.5em;
}
.margin-left-xl {
  margin-left: 3em;
}

.h-2 {
  height: 0.5rem;
}

.v-application {
  font-family: $body-font-family;
}

.text-power {
  top: -5px;
  position: relative;
}

.text-xs {
  font-size: 0.75rem;
}

.text-sm {
  font-size: 0.875rem;
}

.text-base {
  font-size: 1rem;
}

.text-lg {
  font-size: 1.125rem;
}

.text-xl {
  font-size: 1.25rem;
}

.text-2xl {
  font-size: 1.5rem;
}

.text-3xl {
  font-size: 1.875rem;
}

.text-4xl {
  font-size: 2.25rem;
}

.text-5xl {
  font-size: 3rem;
}

.text-6xl {
  font-size: 4rem;
}

.text-7xl {
  font-size: 5rem;
}

.text-8xl {
  font-size: 7rem;
}

.text-2xs {
  font-size: 0.5rem;
}

.text-3xs {
  font-size: 0.25rem;
}

.text-4xs\: {
  font-size: 0.125rem;
}

.border {
  border-width: 1px;
  border-style: solid;
}

.border-gray {
  border-color: #edf2f7;
}

.h-8 {
  height: 2rem;
}

.w-8 {
  width: 2rem;
}
.v-labeltheme--light {
  font-size: 0.875rem !important;
}

.v-label {
  font-size: 0.875rem !important;
}

.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
  border-color: #d6d6d6 !important;
  // border: '' !important;
}

.checkbox {
  width: 100%;
  margin: 15px auto;
  position: relative;
  display: block;
}

.checkbox-active {
  width: 100%;
  margin: 15px auto;
  position: relative;
  display: block;
}

.checkbox input[type='checkbox'] {
  width: auto;
  opacity: 0.00000001;
  position: absolute;
  left: 0;
  margin-left: -20px;
}
.checkbox-active input[type='checkbox'] {
  width: auto;
  opacity: 0.00000001;
  position: absolute;
  left: 0;
  margin-left: -20px;
}
.checkbox label {
  position: relative;
}
.checkbox-active label {
  position: relative;
}
.checkbox label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  margin: 4px;
  width: 18px;
  height: 18px;
  transition: transform 0.28s ease;
  border-radius: 3px;
  border: 2px solid #e2e8f0;
}
.checkbox-active label:before {
  content: '';
  background-color: #5aba46;
  position: absolute;
  left: 0;
  top: 0;
  margin: 4px;
  width: 18px;
  height: 18px;
  transition: transform 0.28s ease;
  border-radius: 3px;
  border: 2px solid #5aba46;
}
.checkbox label:after {
  content: '';
  display: block;
  width: 10px;
  height: 5px;
  border-bottom: 2px solid #fff0;
  border-left: 2px solid #fff0;
  -webkit-transform: rotate(-45deg) scale(0);
  transform: rotate(-45deg) scale(0);
  transition: transform ease 0.25s;
  will-change: transform;
  position: absolute;
  top: 12px;
  left: 10px;
}
.checkbox-active label:after {
  content: '';
  display: block;
  width: 10px;
  height: 5px;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  -webkit-transform: rotate(-45deg) scale(0);
  transform: rotate(-45deg) scale(0);
  transition: transform ease 0.25s;
  will-change: transform;
  position: absolute;
  top: 10px;
  left: 8px;
}
.checkbox input[type='checkbox']:checked ~ label::before {
  color: #fff;
}
.checkbox-active input[type='checkbox']:checked ~ label::before {
  color: #7bbe72;
}

.checkbox input[type='checkbox']:checked ~ label::after {
  -webkit-transform: rotate(-45deg) scale(1);
  transform: rotate(-45deg) scale(1);
}
.checkbox-active input[type='checkbox']:checked ~ label::after {
  -webkit-transform: rotate(-45deg) scale(1);
  transform: rotate(-45deg) scale(1);
}

.checkbox label {
  min-height: 34px;
  display: block;
  padding-left: 40px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
  vertical-align: sub;
}
.checkbox-active label {
  min-height: 34px;
  display: block;
  padding-left: 40px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
  vertical-align: sub;
}
.checkbox label span {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.checkbox-active label span {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.checkbox input[type='checkbox']:focus + label::before {
  outline: 0;
}
.checkbox-active input[type='checkbox']:focus + label::before {
  outline: 0;
}

.carousel-container {
  width: auto;
  height: auto;
  padding: 10px;
  position: relative;
}

.carousel-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}

.carousel-body {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.carousel-item {
  display: none;
}

.active {
  display: inline;
}

.control-container {
  margin: 20px;
}

.left {
  float: right;
}

:root {
  --animation-slide-offset-pos-x: 10%;
  --animation-slide-offset-neg-x: -10%;
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(
      var(--animation-slide-offset-neg-x),
      0,
      0
    );
    transform: translate3d(var(--animation-slide-offset-neg-x), 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(
      var(--animation-slide-offset-neg-x),
      0,
      0
    );
    transform: translate3d(var(--animation-slide-offset-neg-x), 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(
      var(--animation-slide-offset-pos-x),
      0,
      0
    );
    transform: translate3d(var(--animation-slide-offset-pos-x), 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(
      var(--animation-slide-offset-pos-x),
      0,
      0
    );
    transform: translate3d(var(--animation-slide-offset-pos-x), 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(
      var(--animation-slide-offset-neg-x),
      0,
      0
    );
    transform: translate3d(var(--animation-slide-offset-neg-x), 0, 0);
  }
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(
      var(--animation-slide-offset-neg-x),
      0,
      0
    );
    transform: translate3d(var(--animation-slide-offset-neg-x), 0, 0);
  }
}

.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}

@-webkit-keyframes fadeOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(
      var(--animation-slide-offset-pos-x),
      0,
      0
    );
    transform: translate3d(var(--animation-slide-offset-pos-x), 0, 0);
  }
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(
      var(--animation-slide-offset-pos-x),
      0,
      0
    );
    transform: translate3d(var(--animation-slide-offset-pos-x), 0, 0);
  }
}

.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animated.delay-1s {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.animated.delay-2s {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.animated.delay-3s {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.animated.delay-4s {
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}

.animated.delay-5s {
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
}

.animated.fast {
  -webkit-animation-duration: 800ms;
  animation-duration: 800ms;
}

.animated.faster {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
}

.animated.fastest {
  -webkit-animation-duration: 250ms;
  animation-duration: 250ms;
}

.animated.slow {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.animated.slower {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
}

@media (prefers-reduced-motion) {
  .animated {
    -webkit-animation: unset !important;
    animation: unset !important;
    -webkit-transition: none !important;
    transition: none !important;
  }
}
// .v-text-field.v-text-field--enclosed .v-text-field__details,
// .v-text-field.v-text-field--enclosed
//   > .v-input__control
//   > .v-input__slot {
//   margin: 0;
//   max-height: 36px;
//   min-height: 36px !important;
//   display: flex !important;
//   align-items: center !important;
// }
// hr.gray {
//   background-color: $color-gray-border;
//   height: 1px;
//   border: 0;
// }
// Required for modifying core defaults
@import '~vuetify/src/styles/styles.sass';
