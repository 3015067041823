// Override style from v-html content
.html-content {
	color: #252525;
	font-size: 1rem;
	line-height: 1.5;

	@include tablet {
		font-size: 0.875rem;
	}

	*:first-child {
		margin-top: 0;
	}

	*:last-child {
		margin-bottom: 0;
	}

	img,
	object,
	embed {
		max-width: 100% !important;
		height: auto !important;
	}

	iframe,
	video {
		max-width: 100% !important;
	}

	ul,
	ol {
		display: block;

		margin-block-start: 1em;
		margin-block-end: 1em;
		margin-inline-start: 0;
		margin-inline-end: 0;

		padding: 0;
		padding-left: rem(40);
	}

	ul {
		list-style-type: disc;
	}

	ol {
		list-style-type: decimal;
	}

	a {
		color: #eba614;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}

		&:active {
			text-decoration: none;
		}
	}

	// // Variants
	// // Small text
	&.-small {
	font-size: 0.875rem;
	}
}