

.nav-container.header-checkout {
    padding: 0 38px !important
}

.nav
  width: 100%
  margin: 0
  z-index: 10
  background-color: white
  box-shadow: inset 0 -.03125rem #d2d2d7
  height: $header-height-mobile
  display: flex;
  align-items: center;
  @media screen and (min-width: 1024px)
    height: $header-height

.nav-container
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 16px !important;
    @media screen and (min-width: 1024px)
        padding: 28px 16px !important;
        justify-content: start;
    @media screen and (min-width: 1251px)
        padding: 28px 0 !important;
        justify-content: start;
.fixed
  position: fixed
  transition: top 0.2s;

@media small
  .logo
    height: 30px

@media medium
  .logo
    height: 30px

  .text-head
    font-size: 1rem !important

.text-color-bnn
  color: $color-bnn

// $logo-container-height: 40px;
// $space-mobile-gap: 16px;
// $space-desktop-l-gap: 40px; // 2.5 x space-mobile-gap
// $image-mobile-height: 34px;
// $image-tablet-height: 36px;

.premium-partner-img {
    height: 34px;
    margin-left: 16px;

    @media screen and (min-width: 1252px) {
        margin-left: 40px;
    }
}
