
.modal-error
    background-color white
    z-index 10
    overflow hidden

.btn-reload
    border-radius 4px
    padding 1rem
    background-color #D9D9D9
