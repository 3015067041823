
@import '../assets/css/style.styl'

.btn-green
  background-color: $color-green
  padding: 0.5rem
  color: white
  width: 100%
  border-radius: 4px

.btn-white
  background-color: white
  border 1px solid $color-gray-border
  padding: 0.5rem
  color: $color-dark-gray
  width: 100%
  border-radius: 4px

.btn-gray
  background-color: $color-gray-100
  color: $color-black-100
  padding: 0.5rem
  width: 100%
  border-radius: 4px

.isLoading
  cursor not-allowed !important
  opacity 0.6
